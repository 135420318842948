(function (root, factory) {
  'use strict';

  // Universal Module Definition (UMD) to support AMD, CommonJS/Node.js, Rhino, and browsers.

  /* istanbul ignore next */
  if (typeof define === 'function' && define.amd) {
    define('stackframe', [], factory);
  } else if (typeof exports === 'object') {
    module.exports = factory();
  } else {
    root.StackFrame = factory();
  }
})(this, function () {
  'use strict';

  function _isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }
  function StackFrame(functionName, args, fileName, lineNumber, columnNumber, source) {
    if (functionName !== undefined) {
      this.setFunctionName(functionName);
    }
    if (args !== undefined) {
      this.setArgs(args);
    }
    if (fileName !== undefined) {
      this.setFileName(fileName);
    }
    if (lineNumber !== undefined) {
      this.setLineNumber(lineNumber);
    }
    if (columnNumber !== undefined) {
      this.setColumnNumber(columnNumber);
    }
    if (source !== undefined) {
      this.setSource(source);
    }
  }
  StackFrame.prototype = {
    getFunctionName: function () {
      return this.functionName;
    },
    setFunctionName: function (v) {
      this.functionName = String(v);
    },
    getArgs: function () {
      return this.args;
    },
    setArgs: function (v) {
      if (Object.prototype.toString.call(v) !== '[object Array]') {
        throw new TypeError('Args must be an Array');
      }
      this.args = v;
    },
    // NOTE: Property name may be misleading as it includes the path,
    // but it somewhat mirrors V8's JavaScriptStackTraceApi
    // https://code.google.com/p/v8/wiki/JavaScriptStackTraceApi and Gecko's
    // http://mxr.mozilla.org/mozilla-central/source/xpcom/base/nsIException.idl#14
    getFileName: function () {
      return this.fileName;
    },
    setFileName: function (v) {
      this.fileName = String(v);
    },
    getLineNumber: function () {
      return this.lineNumber;
    },
    setLineNumber: function (v) {
      if (!_isNumber(v)) {
        throw new TypeError('Line Number must be a Number');
      }
      this.lineNumber = Number(v);
    },
    getColumnNumber: function () {
      return this.columnNumber;
    },
    setColumnNumber: function (v) {
      if (!_isNumber(v)) {
        throw new TypeError('Column Number must be a Number');
      }
      this.columnNumber = Number(v);
    },
    getSource: function () {
      return this.source;
    },
    setSource: function (v) {
      this.source = String(v);
    },
    toString: function () {
      var functionName = this.getFunctionName() || '{anonymous}';
      var args = '(' + (this.getArgs() || []).join(',') + ')';
      var fileName = this.getFileName() ? '@' + this.getFileName() : '';
      var lineNumber = _isNumber(this.getLineNumber()) ? ':' + this.getLineNumber() : '';
      var columnNumber = _isNumber(this.getColumnNumber()) ? ':' + this.getColumnNumber() : '';
      return functionName + args + fileName + lineNumber + columnNumber;
    }
  };
  return StackFrame;
});